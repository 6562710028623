import { Box, CircularProgress } from '@mui/material';
import { FunctionComponent } from 'react';

interface IProps {
  size?: number;
}

export const LoadingIndicator: FunctionComponent<IProps> = ({ size }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <CircularProgress size={size} />
  </Box>
);
