/* eslint-disable react-hooks/rules-of-hooks */
import {
  errorHandler,
  generate2848FilesQueryKey,
  generateDocumentObjectsQueryKey,
} from 'helpers';
import { ChangeEvent } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { DocumentObject, S3File } from 'types';
import { DocumentObjectProperties } from 'types/dealTypes';
import { useUserToken } from '.';

export const getDocumentObjectMutation = (
  updateDocumentObjectHandler: (
    documentProperties: Partial<DocumentObjectProperties>,
  ) => Promise<void>,
  dealId: string,
  documentObject: DocumentObject,
) => {
  const qc = useQueryClient();
  const token = useUserToken();

  return useMutation<
    void,
    unknown,
    Partial<DocumentObjectProperties>,
    { previousDocumentObjects: DocumentObject[] | undefined }
  >(updateDocumentObjectHandler, {
    onMutate: (newProperties) => {
      const previousDocumentObjects: DocumentObject[] | undefined =
        qc.getQueryData(generateDocumentObjectsQueryKey(token, dealId));
      const newDocumentObject: DocumentObject = {
        ...documentObject,
        properties: { ...documentObject.properties },
      };
      newDocumentObject.properties.internalNotes =
        newProperties.internalNotes ??
        newDocumentObject.properties.internalNotes;
      newDocumentObject.properties.fileNamingConvention =
        newProperties.fileNamingConvention ??
        newDocumentObject.properties.fileNamingConvention;
      newDocumentObject.properties.status =
        newProperties.status ?? newDocumentObject.properties.status;
      if (newDocumentObject.properties.status === 'Not Yet Received') {
        newDocumentObject.properties.invalidReason =
          newProperties.invalidReason ??
          newDocumentObject.properties.invalidReason;
      } else {
        newDocumentObject.properties.invalidReason = '';
      }

      qc.setQueryData(
        generateDocumentObjectsQueryKey(token, dealId),
        (oldDocumentObjects: DocumentObject[] | undefined) => {
          const indexOfDocumentObjectToOverwrite =
            oldDocumentObjects?.findIndex(
              (docObj) => docObj.id === newDocumentObject.id,
            );
          if (indexOfDocumentObjectToOverwrite && oldDocumentObjects) {
            const newDocumentObjects = [...oldDocumentObjects];
            newDocumentObjects[indexOfDocumentObjectToOverwrite] =
              newDocumentObject;
            return newDocumentObjects;
          }
          return oldDocumentObjects ?? [];
        },
      );
      return { previousDocumentObjects };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateDocumentObjectsQueryKey(token, dealId),
        context?.previousDocumentObjects,
      );
    },
    onSettled: () => {
      qc.invalidateQueries(generateDocumentObjectsQueryKey(token, dealId));
    },
  });
};

export const getDocumentObjectMutationForUploadProperties = (
  updateDocumentObjectHandler: (
    event: ChangeEvent<HTMLInputElement>,
  ) => Promise<void>,
  dealId: string,
  documentObject: DocumentObject,
) => {
  const qc = useQueryClient();
  const token = useUserToken();

  return useMutation<
    void,
    unknown,
    ChangeEvent<HTMLInputElement>,
    { previousDocumentObjects: DocumentObject[] | undefined }
  >(updateDocumentObjectHandler, {
    onMutate: (event) => {
      const fileName = event.target.files?.[0]?.name;
      const previousDocumentObjects: DocumentObject[] | undefined =
        qc.getQueryData(generateDocumentObjectsQueryKey(token, dealId));
      if (fileName) {
        const newDocumentObject: DocumentObject = {
          ...documentObject,
          properties: { ...documentObject.properties },
        };
        newDocumentObject.properties.s3FileName = fileName;
        newDocumentObject.properties.fileLastModifiedDate =
          new Date().toISOString();

        qc.setQueryData(
          generateDocumentObjectsQueryKey(token, dealId),
          (oldDocumentObjects: DocumentObject[] | undefined) => {
            const indexOfDocumentObjectToOverwrite =
              oldDocumentObjects?.findIndex(
                (docObj) => docObj.id === newDocumentObject.id,
              );
            if (indexOfDocumentObjectToOverwrite && oldDocumentObjects) {
              const newDocumentObjects = [...oldDocumentObjects];
              newDocumentObjects[indexOfDocumentObjectToOverwrite] =
                newDocumentObject;
              return newDocumentObjects;
            }
            return oldDocumentObjects ?? [];
          },
        );
      }
      return { previousDocumentObjects };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateDocumentObjectsQueryKey(token, dealId),
        context?.previousDocumentObjects,
      );
    },
    onSettled: () => {
      qc.invalidateQueries(generateDocumentObjectsQueryKey(token, dealId));
    },
  });
};

export const getMutationFor2848Files = (
  uploadFileHandler: (event: ChangeEvent<HTMLInputElement>) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const token = useUserToken();

  return useMutation<
    void,
    unknown,
    ChangeEvent<HTMLInputElement>,
    { previous2848Documents: S3File[] | undefined }
  >(uploadFileHandler, {
    onMutate: (event) => {
      const fileName = event.target.files?.[0]?.name;
      const previous2848Documents: S3File[] | undefined = qc.getQueryData(
        generate2848FilesQueryKey(token, dealId),
      );
      if (fileName) {
        let newDealDocument: S3File;
        if (previous2848Documents && previous2848Documents?.length > 0) {
          newDealDocument = {
            ...previous2848Documents?.[0],
          };
          newDealDocument.name = fileName;
        } else {
          newDealDocument = {
            name: fileName,
            size: 0,
            lastModified: '',
            contentType: '',
            url: '',
          };
        }

        qc.setQueryData(generate2848FilesQueryKey(token, dealId), () => [
          newDealDocument,
        ]);
      }
      return { previous2848Documents };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generate2848FilesQueryKey(token, dealId),
        context?.previous2848Documents,
      );
    },
    onSettled: () => {
      qc.invalidateQueries(generate2848FilesQueryKey(token, dealId));
    },
  });
};
