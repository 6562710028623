/* eslint-disable react-hooks/rules-of-hooks */
import { errorHandler, generateCreditObjectsQueryKey } from 'helpers';
import { ChangeEvent } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { CreditObject, CreditObjectProperties } from 'types';
import { useUserToken } from './cognito';

export const getCreditObjectMutation = (
  updateCreditObjectHandler: (
    creditProperties: Partial<CreditObjectProperties>,
  ) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const token = useUserToken();

  return useMutation<
    void,
    unknown,
    Partial<CreditObjectProperties>,
    { previousCreditObjects: CreditObject[] | undefined }
  >(updateCreditObjectHandler, {
    onMutate: (newProperties) => {
      const previousCreditObjects: CreditObject[] | undefined = qc.getQueryData(
        generateCreditObjectsQueryKey(token, dealId),
      );
      qc.setQueryData(
        generateCreditObjectsQueryKey(token, dealId),
        (oldCreditObjects: CreditObject[] | undefined) => {
          if (oldCreditObjects) {
            const creditObjectToOverwrite = oldCreditObjects?.find(
              (creditObj) => creditObj.properties.name === newProperties.name,
            );
            if (creditObjectToOverwrite) {
              creditObjectToOverwrite.properties.s3FileName =
                newProperties.s3FileName ??
                creditObjectToOverwrite.properties.s3FileName;
              creditObjectToOverwrite.properties.estimatedRefundAmount =
                newProperties.estimatedRefundAmount ??
                creditObjectToOverwrite.properties.estimatedRefundAmount;
            }
          }
          return oldCreditObjects ?? [];
        },
      );
      return { previousCreditObjects };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateCreditObjectsQueryKey(token, dealId),
        context?.previousCreditObjects,
      );
    },
    onSettled: () => {
      qc.invalidateQueries(generateCreditObjectsQueryKey(token, dealId));
    },
  });
};

export const getCreateCreditObjectMutation = (
  createCreditObjectHandler: (
    creditProperties: Partial<CreditObjectProperties>,
  ) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const token = useUserToken();

  return useMutation<void, unknown, Partial<CreditObjectProperties>>(
    createCreditObjectHandler,
    {
      onError: (err) => {
        errorHandler(err);
      },
      onSettled: () => {
        qc.invalidateQueries(generateCreditObjectsQueryKey(token, dealId));
      },
    },
  );
};

export const getDeleteCreditObjectMutation = (
  deleteCreditObjectHandler: (creditId: string) => Promise<void>,
  dealId: string,
) => {
  const qc = useQueryClient();
  const token = useUserToken();

  return useMutation<void, unknown, string>(deleteCreditObjectHandler, {
    onMutate: (creditId) => {
      const previousCreditObjects: CreditObject[] | undefined = qc.getQueryData(
        generateCreditObjectsQueryKey(token, dealId),
      );
      qc.setQueryData(
        generateCreditObjectsQueryKey(token, dealId),
        (oldCreditObjects: CreditObject[] | undefined) =>
          oldCreditObjects?.filter((creditObj) => creditObj.id !== creditId) ??
          [],
      );
      return { previousCreditObjects };
    },
    onError: (err) => {
      errorHandler(err);
    },
    onSettled: () => {
      qc.invalidateQueries(generateCreditObjectsQueryKey(token, dealId));
    },
  });
};

export const getCreditObjectMutationForUploadProperties = (
  updateCreditObjectHandler: (
    event: ChangeEvent<HTMLInputElement>,
  ) => Promise<void>,
  dealId: string,
  creditObject: CreditObject | undefined,
) => {
  const qc = useQueryClient();
  const token = useUserToken();

  return useMutation<
    void,
    unknown,
    ChangeEvent<HTMLInputElement>,
    { previousCreditObjects: CreditObject[] | undefined }
  >(updateCreditObjectHandler, {
    onMutate: (event) => {
      const fileName = event.target.files?.[0]?.name;
      const previousCreditObjects: CreditObject[] | undefined = qc.getQueryData(
        generateCreditObjectsQueryKey(token, dealId),
      );
      if (fileName && creditObject) {
        const newCreditObject: CreditObject = {
          ...creditObject,
          properties: { ...creditObject.properties },
        };
        newCreditObject.properties.s3FileName = fileName;

        qc.setQueryData(
          generateCreditObjectsQueryKey(token, dealId),
          (oldCreditObjects: CreditObject[] | undefined) => {
            const indexOfCreditObjectToOverwrite = oldCreditObjects?.findIndex(
              (docObj) => docObj.id === newCreditObject.id,
            );
            if (indexOfCreditObjectToOverwrite && oldCreditObjects) {
              const newCreditObjects = [...oldCreditObjects];
              newCreditObjects[indexOfCreditObjectToOverwrite] =
                newCreditObject;
              return newCreditObjects;
            }
            return oldCreditObjects ?? [];
          },
        );
      }
      return { previousCreditObjects };
    },
    onError: (err, event, context) => {
      errorHandler(err);
      qc.setQueryData(
        generateCreditObjectsQueryKey(token, dealId),
        context?.previousCreditObjects,
      );
    },
    onSettled: () => {
      qc.invalidateQueries(generateCreditObjectsQueryKey(token, dealId));
    },
  });
};
