import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { updateDocumentProperties } from 'api';
import { getDocumentObjectMutation, useUserToken } from 'dataLayer';
import { successHandler } from 'helpers';
import { FunctionComponent, SyntheticEvent, useState } from 'react';
import { DocumentObject } from 'types';
import { DocumentObjectProperties } from 'types/dealTypes';

interface IProps {
  documentObject: DocumentObject;
  dealId: string;
}

export const EditDocumentModal: FunctionComponent<IProps> = ({
  documentObject,
  dealId,
}) => {
  const token = useUserToken();
  const [openModal, setOpenModal] = useState(false);
  const [fileNamingConvention, setFileNamingConvention] = useState(
    documentObject.properties.fileNamingConvention,
  );
  const [notes, setNotes] = useState(documentObject.properties.internalNotes);

  const updateDocumentObjectHandler = async (
    documentProperties: Partial<DocumentObjectProperties>,
  ) => {
    const documentId = documentObject.id;
    await updateDocumentProperties(token, [documentId], documentProperties);
    successHandler('Other properties successfully saved');
    setOpenModal(false);
  };

  const updateDocumentObjectMutation = getDocumentObjectMutation(
    updateDocumentObjectHandler,
    dealId,
    documentObject,
  );

  const handleClose = (event: SyntheticEvent, reason: string) => {
    if (reason && reason === 'backdropClick') return;
    setOpenModal(false);
  };

  return (
    <>
      <IconButton id="modal-open-button" onClick={() => setOpenModal(true)}>
        <Badge
          color="error"
          variant="dot"
          invisible={!documentObject.properties.internalNotes}
        >
          <MoreVertIcon />
        </Badge>
      </IconButton>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 1,
            py: 3,
            px: 2,
          }}
        >
          <Typography
            id="modal-modal-title"
            fontFamily="Crique Grotesk"
            fontSize="25px"
          >
            {documentObject.properties.displayName}
          </Typography>
          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            id="outlined-multiline-static"
            label="Naming Convention"
            defaultValue={documentObject.properties.fileNamingConvention}
            onChange={(event) => setFileNamingConvention(event.target.value)}
          />
          <TextField
            sx={{ marginY: 2 }}
            fullWidth
            id="outlined-multiline-static"
            label="Internal Notes"
            multiline
            rows={4}
            defaultValue={documentObject.properties.internalNotes}
            onChange={(event) => setNotes(event.target.value)}
          />
          <Box sx={{ marginTop: 1, display: 'flex' }}>
            <Button
              sx={{ marginRight: 0.5, flexGrow: 1, flexBasis: 0 }}
              variant="outlined"
              color="error"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{ marginLeft: 0.5, flexGrow: 1, flexBasis: 0 }}
              variant="contained"
              disabled={updateDocumentObjectMutation.isLoading}
              onClick={() =>
                updateDocumentObjectMutation.mutate({
                  internalNotes: notes,
                  fileNamingConvention,
                })
              }
            >
              {updateDocumentObjectMutation.isLoading ? (
                <CircularProgress size={20} />
              ) : (
                'Save'
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
