import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { getDocumentProperties } from 'api';
import { CscDocumentTableCells } from 'components/TableCells';
import { Footer } from 'components/Footer';
import { DealHeader, Header } from 'components/Header';
import { LoadingIndicator } from 'components/LoadingIndiciator';
import { useUserToken } from 'dataLayer';
import {
  CACHE_TIME,
  errorHandler,
  generateDocumentObjectsQueryKey,
  STALE_TIME,
} from 'helpers';
import { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { RouteProps, useParams } from 'react-router-dom';
import { DocumentObject } from 'types/dealTypes';
import {
  TableCommonTypography,
  TableHeaderTypography,
} from 'components/Typography';
import {
  dataStatusColumnStyle,
  documentNameColumnStyle,
  invalidReasonsColumnStyle,
  noteColumnStyle,
  rotateFileColumnStyle,
  statusColumnStyle,
  uploadDateColumnStyle,
} from '.';

const years = ['2021', '2020', '2019'];

export const DealDocuments: FunctionComponent<RouteProps> = () => {
  const token = useUserToken();

  const { dealId } = useParams() as { dealId: string };
  // const { data: files } = useQuery<DealDocument[]>({
  //   queryKey: ['data', 'dealDocuments', token, dealId],
  //   queryFn: () => getFilesForDeal(token, dealId, 'documents'),
  //   cacheTime: CACHE_TIME,
  //   staleTime: STALE_TIME,
  //   onError: (error) => errorHandler(error),
  // });

  const { data: documentObjects, isLoading: isLoadingDocumentObjects } =
    useQuery<DocumentObject[]>({
      queryKey: generateDocumentObjectsQueryKey(token, dealId),
      queryFn: () => getDocumentProperties(token, dealId),
      cacheTime: CACHE_TIME,
      staleTime: STALE_TIME,
      onError: (error) => errorHandler(error),
    });

  const renderSubHeader = () => (
    <>
      <DealHeader dealId={dealId} />
      <Typography
        fontFamily="Crique Grotesk"
        fontSize="25px"
        color="rgba(0,0,0)"
        fontWeight="800"
        marginBottom={2}
      >
        New Uploads
      </Typography>
      <Typography
        fontFamily="Crique Grotesk"
        fontSize="15px"
        color="rgba(0,0,0)"
        marginBottom={2}
      >
        New uploads ready for review, association and status confirmation.
      </Typography>
    </>
  );

  const renderFilesList = (label: string, documents?: DocumentObject[]) => (
    <>
      <Typography
        fontFamily="Crique Grotesk"
        fontSize="25px"
        color="rgba(0,0,0)"
        fontWeight="800"
        marginY={2}
      >
        {label}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              height: '75px',
            }}
          >
            <TableRow
              sx={{
                backgroundColor: '#dfe8f0',
              }}
            >
              <TableCell sx={statusColumnStyle.containerStyle} />
              <TableCell sx={documentNameColumnStyle.containerStyle}>
                <TableHeaderTypography text="Document Name" />
                <TableCommonTypography text="Upload File Name" />
              </TableCell>
              <TableCell sx={uploadDateColumnStyle.containerStyle}>
                <TableHeaderTypography text="Uploaded" />
              </TableCell>
              <TableCell sx={rotateFileColumnStyle.containerStyle}>
                <TableHeaderTypography text="Rotate" />
                <TableCommonTypography text="JPEG, PNG, PDF" />
              </TableCell>
              <TableCell sx={dataStatusColumnStyle.containerStyle}>
                <TableHeaderTypography text="Change Status" />
              </TableCell>
              <TableCell sx={invalidReasonsColumnStyle.containerStyle}>
                <TableHeaderTypography text="Reason Invalidated" />
              </TableCell>
              <TableCell sx={noteColumnStyle.containerStyle} />
            </TableRow>
          </TableHead>
          <TableBody>
            {documents?.map((document) => (
              <TableRow
                key={document.properties.hubspotObjectId}
                sx={{
                  boxShadow: '0px 1px 0px lightgray',
                }}
              >
                <CscDocumentTableCells
                  documentObject={document}
                  dealId={dealId}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return (
    <>
      <Header />
      {renderSubHeader()}
      {isLoadingDocumentObjects ? (
        <LoadingIndicator />
      ) : (
        <>
          {years.map((year) =>
            renderFilesList(
              year,
              documentObjects?.filter(
                (document) => document.properties.year === year,
              ),
            ),
          )}
          {renderFilesList(
            'Other',
            documentObjects?.filter(
              (document) => !years.includes(document.properties.year),
            ),
          )}
        </>
      )}
      <Footer />
    </>
  );
};
