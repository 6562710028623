import axios, { AxiosError } from 'axios';
import { successHandler } from 'helpers';
import {
  S3File,
  GetDealIdsResponse,
  GetFilesLambdaResponse,
  InvalidReason,
  CreditObject,
  DealObject,
  DocumentObject,
} from 'types';
import {
  CreditObjectProperties,
  DocumentObjectProperties,
} from 'types/dealTypes';

const FILES_URL = '/files';
const ZIP_URL = '/zip';
const GET_DEALS_URL = '/deals';
const ROTATE_FILE_URL = '/rotate';
const ARCHIVE_FILE_URL = '/archive';
const HUBSPOT_DEALS_URL = '/hubspot/deals';
const HUBSPOT_DOCUMENTS_URL = '/hubspot/documents';
const HUBSPOT_INVALIDREASONS_URL = '/hubspot/invalidreasons';
const HUBSPOT_BQF_DISPLAY_NAMES_URL = '/hubspot/bqfdisplaynames';
const HUBSPOT_CREDITS_URL = '/hubspot/credits';

const lambdaAxios = axios.create({
  baseURL: `https://${process.env.REACT_APP_BACKEND_URL}`,
});

const generateHeaders = (token: string) => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: token,
  },
});

const getUniqueS3ObjectPath = async (
  token: string | undefined,
  dealId: string,
  fileName: string,
  fileType: string,
  index = 0,
): Promise<{ fileName: string; putUrl: string; getUrl: string }> => {
  const uniqueKey = index ? `${fileName} (${index})` : fileName;

  try {
    const encodedFilename = encodeURIComponent(uniqueKey);
    const response = await lambdaAxios.put(
      `${FILES_URL}/${dealId}/${encodedFilename}?fileType=${fileType}`,
      undefined,
      generateHeaders(token!),
    );
    return { fileName: uniqueKey, ...response.data };
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 409) {
      return getUniqueS3ObjectPath(
        token,
        dealId,
        fileName,
        fileType,
        index + 1,
      );
    }
    throw error;
  }
};

export const getFilesForDeal = async (
  token: string | undefined,
  dealId: string,
  fileType: string,
) => {
  if (!token) return [];

  const response = await lambdaAxios.get<GetFilesLambdaResponse>(
    `${FILES_URL}/${dealId}?fileType=${fileType}`,
    generateHeaders(token),
  );
  return response.data.files;
};

export const getFile = async (
  token: string | undefined,
  dealId: string,
  fileName: string,
  fileType: string,
) => {
  if (!token) return undefined;

  const encodedFilename = encodeURIComponent(fileName);
  const response = await lambdaAxios.get<S3File>(
    `${FILES_URL}/${dealId}/${encodedFilename}?fileType=${fileType}`,
    generateHeaders(token),
  );
  return response.data;
};

export const getZippedFiles = async (
  token: string | undefined,
  dealId: string,
  fileType: string,
) => {
  if (!token) return null;

  const response = await lambdaAxios.get<{ url: string }>(
    `${ZIP_URL}/${dealId}?fileType=${fileType}`,
    generateHeaders(token),
  );
  return response.data;
};

export const uploadFile = async (
  token: string | undefined,
  dealId: string,
  fileName: string,
  fileType: string,
  file: File,
) => {
  if (!token) return '';
  const response = await getUniqueS3ObjectPath(
    token,
    dealId,
    fileName,
    fileType,
  );

  const buffer = await file.arrayBuffer();
  await axios.put(response.putUrl, buffer, {
    headers: { 'Content-Type': file.type },
  });
  successHandler('File successfully uploaded!');
  return response.fileName;
};

export const getDealIds = async (token: string | undefined, prefix: string) => {
  if (!token) return [];

  const response = await lambdaAxios.get<GetDealIdsResponse>(
    `${GET_DEALS_URL}?prefix=${prefix}`,
    generateHeaders(token),
  );
  return response.data.dealIds;
};

export const rotateFile = async (
  token: string | undefined,
  dealId: string,
  fileName: string,
  rotationInDegrees: string,
  fileType: string,
) => {
  if (!token) return;

  const encodedFilename = encodeURIComponent(fileName);
  await lambdaAxios.post(
    `${ROTATE_FILE_URL}/${dealId}/${encodedFilename}/${rotationInDegrees}?fileType=${fileType}`,
    undefined,
    generateHeaders(token),
  );
};

export const getInvalidReasons = async (token: string | undefined) => {
  if (!token) return undefined;

  const response = await lambdaAxios.get<InvalidReason[]>(
    `${HUBSPOT_INVALIDREASONS_URL}`,
    generateHeaders(token),
  );
  return response.data;
};

export const getDealProperties = async (
  token: string | undefined,
  dealId: string,
) => {
  if (!token) return undefined;

  const response = await lambdaAxios.get<DealObject>(
    `${HUBSPOT_DEALS_URL}/${dealId}`,
    generateHeaders(token),
  );
  return response.data;
};

export const getDocumentProperties = async (
  token: string | undefined,
  dealId: string,
) => {
  if (!token) return [];

  const response = await lambdaAxios.get<DocumentObject[]>(
    `${HUBSPOT_DOCUMENTS_URL}/${dealId}`,
    generateHeaders(token),
  );

  return response.data;
};

export const updateDocumentProperties = async (
  token: string | undefined,
  documentObjectIds: string[],
  properties: Partial<DocumentObjectProperties>,
) => {
  if (!token) return;
  if (documentObjectIds.length === 0) return;

  const url = `${HUBSPOT_DOCUMENTS_URL}?hubspotObjectId=${documentObjectIds.join(
    '&hubspotObjectId=',
  )}`;

  await lambdaAxios.post(url, properties, generateHeaders(token));
};

export const getBqfDisplayNames = async (token: string | undefined) => {
  if (!token) return undefined;

  const response = await lambdaAxios.get<DealObject>(
    `${HUBSPOT_BQF_DISPLAY_NAMES_URL}`,
    generateHeaders(token),
  );
  return response.data;
};

export const archiveFile = async (
  token: string | undefined,
  dealId: string,
  fileName: string,
  fileType: string,
) => {
  if (!token) return;

  const encodedFilename = encodeURIComponent(fileName);
  await lambdaAxios.put(
    `${ARCHIVE_FILE_URL}/${dealId}/${encodedFilename}?fileType=${fileType}`,
    undefined,
    generateHeaders(token),
  );
};

export const getCreditObjects = async (
  token: string | undefined,
  dealId: string,
) => {
  if (!token) return [];

  const response = await lambdaAxios.get<CreditObject[]>(
    `${HUBSPOT_CREDITS_URL}/${dealId}`,
    generateHeaders(token),
  );
  return response.data;
};

export const createCreditObject = async (
  token: string | undefined,
  dealId: string,
  creditProperties: Partial<CreditObjectProperties>,
) => {
  if (!token) return;

  await lambdaAxios.put(
    `${HUBSPOT_CREDITS_URL}/${dealId}`,
    creditProperties,
    generateHeaders(token),
  );
};

export const deleteCreditObject = async (
  token: string | undefined,
  dealId: string,
  creditId: string,
) => {
  if (!token) return;

  await lambdaAxios.delete(
    `${HUBSPOT_CREDITS_URL}/${dealId}/${creditId}`,
    generateHeaders(token),
  );
};

export const updateCreditObjects = async (
  token: string | undefined,
  creditObjectIds: string[],
  creditProperties: Partial<CreditObjectProperties>,
) => {
  if (!token) return;
  if (creditObjectIds.length === 0) return;

  const url = `${HUBSPOT_CREDITS_URL}?hubspotObjectId=${creditObjectIds.join(
    '&hubspotObjectId=',
  )}`;

  await lambdaAxios.post(url, creditProperties, generateHeaders(token));
};
