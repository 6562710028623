import { ArrowBackIosNew } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useIsAuthenticated, useLogout } from 'dataLayer';
import logo from 'logo-indigo.png';
import { FunctionComponent } from 'react';
import { RouteProps, useNavigate } from 'react-router-dom';

export const Header: FunctionComponent<RouteProps> = () => {
  const navigate = useNavigate();

  const { isAuthenticated } = useIsAuthenticated();
  const { logout } = useLogout();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          margin: '0 -10vw',
          padding: 1,
          backgroundColor: 'white',
          borderColor: 'divider',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: '10vw', padding: 1, color: '#25215B' }}>
          {!!window.history.state.idx && (
            <Button color="inherit" onClick={() => navigate(-1)}>
              <ArrowBackIosNew fontSize="small" sx={{ marginRight: 1 }} />
              Back
            </Button>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'rgba(255, 255, 255)',
            paddingRight: '10vw',
            width: '100%',
          }}
        >
          <Box component="img" sx={{ width: 200 }} src={logo} alt="logo" />
          {isAuthenticated && (
            <Button
              sx={{
                marginLeft: 'auto',
                color: 'rgba(95,98,171)',
                borderColor: 'rgba(213,210,234)',
              }}
              variant="outlined"
              onClick={logout}
            >
              Logout
            </Button>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          marginX: '-10vw',
          height: '12px',
          maxHeight: '1vw',
        }}
      >
        <Box
          sx={{
            background: '#24A66F',
            width: '10%',
          }}
        />
        <Box
          sx={{
            background: 'linear-gradient(90deg, #23A56E 0%, #24205A 100%)',
            width: '80%',
          }}
        />
        <Box
          sx={{
            background: '#25215B',
            width: '10%',
          }}
        />
      </Box>
    </>
  );
};
