import { toast, Zoom } from 'react-toastify';

export const allowedContentTypes = (contentType: string) => {
  switch (contentType) {
    case 'application/pdf':
    case 'image/jpeg':
    case 'image/png':
      return true;
    default:
      return false;
  }
};

export const errorHandler = (error: unknown) => {
  console.error(error);
  toast.error((error as Error).toString(), {
    position: 'top-center',
    autoClose: 5000,
    transition: Zoom,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const successHandler = (message: string) => {
  toast.success(message, {
    position: 'top-center',
    autoClose: 5000,
    transition: Zoom,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const infoHandler = (message: string) => {
  toast.info(message, {
    position: 'top-center',
    autoClose: false,
    transition: Zoom,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
