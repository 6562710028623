import { infoHandler } from 'helpers';
import { IDLE_TIMEOUT } from 'helpers/constants';
import { DealCPA } from 'pages/Deals';
import { BusinessQualifyForm } from 'pages/Deals/Deal';
import { FunctionComponent, Suspense, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useIsAuthenticated, useLogout, useUserGroups } from './dataLayer';
import { DealDocuments, DealsHome } from './pages/Deals';
import { Login } from './pages/Login';

const DEAL = 'deal';
const DEAL_ID = ':dealId';
const DOCUMENTS = 'documents';
const BQF = 'overview';
const CPA = 'cpa';

export const PATHS = {
  DEALS: ['', DEAL].join('/'),
  DOCUMENTS: ['', DEAL, DEAL_ID, DOCUMENTS].join('/'),
  DEAL_CPA: ['', DEAL, DEAL_ID, CPA].join('/'),
  BQF: ['', DEAL, DEAL_ID, BQF].join('/'),
};

const AppRoutes: FunctionComponent = () => {
  const { isAuthenticated, isLoading } = useIsAuthenticated();
  const userGroups = useUserGroups();
  const { logout } = useLogout();
  const [isIdle, setIsIdle] = useState(false);

  const onIdle = () => setIsIdle(true);

  const onActive = () => setIsIdle(false);

  useIdleTimer({ onIdle, onActive, timeout: IDLE_TIMEOUT });

  useEffect(() => {
    if (isIdle && isAuthenticated) {
      logout();
      infoHandler('You have been logged out due to inactivity.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle]);

  return isLoading ? null : (
    <Suspense fallback={<div />}>
      <Routes>
        {!isAuthenticated && <Route path="/*" element={<Login />} />}
        {isAuthenticated && (
          <>
            <Route path={DEAL}>
              <Route index element={<DealsHome />} />
              <Route path={DEAL_ID}>
                {userGroups.includes('csc') && (
                  <Route path={DOCUMENTS} element={<DealDocuments />} />
                )}
                {userGroups.includes('cpa') && (
                  <Route path={CPA} element={<DealCPA />} />
                )}
                <Route path={BQF} element={<BusinessQualifyForm />} />
                <Route path="" element={<Navigate to={DOCUMENTS} />} />
              </Route>
            </Route>
            <Route path="*" element={<Navigate to={DEAL} replace />} />
          </>
        )}
      </Routes>
    </Suspense>
  );
};

const Router: FunctionComponent = () => (
  <BrowserRouter>
    <AppRoutes />
  </BrowserRouter>
);

export default Router;
