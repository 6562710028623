import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAsync } from '@react-hookz/web';

interface IProps {
  onSubmitMethod: (newPassword: string) => Promise<void>;
}

export const UpdatePassword: FunctionComponent<IProps> = ({
  onSubmitMethod,
}) => {
  const formSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        'Must contain at least 8 characters, one uppercase, one lowercase, and one number',
      ),
    confirmPassword: Yup.string()
      .required('Please confirm new password')
      .oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
  });

  const formOptions = {
    resolver: yupResolver(formSchema),
    defaultValues: { newPassword: '', confirmPassword: '' },
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const [formSubmitState, formSubmitAction] = useAsync(
    async (data: Record<string, string>) => {
      await onSubmitMethod(data.newPassword);
    },
  );

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      onSubmit={handleSubmit(formSubmitAction.execute)}
    >
      <Typography sx={{ margin: 1 }} variant="h4">
        Update Password
      </Typography>
      <Controller
        name="newPassword"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors.newPassword}
            helperText={errors.newPassword?.message}
            sx={{ margin: 1 }}
            label="New Password"
            type="password"
          />
        )}
      />
      <Controller
        name="confirmPassword"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            sx={{ margin: 1 }}
            label="Confirm New Password"
            type="password"
          />
        )}
      />
      <Button sx={{ margin: 1 }} variant="outlined" type="submit">
        {formSubmitState.status === 'loading' && (
          <CircularProgress size={20} style={{ marginRight: 20 }} />
        )}
        Update Password
      </Button>
    </form>
  );
};
