import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material';
import { updateDocumentProperties, getInvalidReasons, uploadFile } from 'api';
import { DownloadButton, UploadButton } from 'components/Buttons';
import { EditDocumentModal } from 'components/Modals';
import { TableBoldedTypography } from 'components/Typography';
import {
  getDocumentObjectMutation,
  getDocumentObjectMutationForUploadProperties,
  useUserToken,
} from 'dataLayer';
import { format, parseISO } from 'date-fns';
import {
  allowedContentTypes,
  CACHE_TIME,
  errorHandler,
  generateInvalidReasonsQueryKey,
  STALE_TIME,
} from 'helpers';
import {
  dataStatusColumnStyle,
  documentNameColumnStyle,
  invalidReasonsColumnStyle,
  noteColumnStyle,
  rotateFileColumnStyle,
  statusColumnStyle,
  uploadDateColumnStyle,
} from 'pages/Deals/Deal';
import { ChangeEvent, FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { DocumentObject } from 'types';
import { DocumentObjectProperties } from 'types/dealTypes';
import { RotateDropdownMenu } from '../DropdownMenus/RotateDropdownMenu';

interface IProps {
  documentObject: DocumentObject;
  dealId: string;
  // fileName: string;
}

export const CscDocumentTableCells: FunctionComponent<IProps> = ({
  documentObject,
  dealId,

  // fileName,
}) => {
  const token = useUserToken();
  const statusOptions = [
    { displayName: '1 - Upload required', hubspotName: 'Not Yet Received' },
    { displayName: '2 - Pending review', hubspotName: 'Pending IR Review' },
    { displayName: '3 - CSC confirmed', hubspotName: 'Confirmed by IR' },
    { displayName: '4 - QC confirmed', hubspotName: 'QC Confirmed' },
  ];

  const { data: invalidReasons, isLoading: isLoadingInvalidReasonOptions } =
    useQuery({
      queryKey: generateInvalidReasonsQueryKey(token),
      queryFn: () => getInvalidReasons(token),
      cacheTime: CACHE_TIME,
      staleTime: STALE_TIME,
      onError: (error) => errorHandler(error),
    });

  const updateDocumentObjectHandler = async (
    documentProperties: Partial<DocumentObjectProperties>,
  ) => {
    const documentId = documentObject.id;
    await updateDocumentProperties(token, [documentId], documentProperties);
  };

  const updateDocumentObjectMutation = getDocumentObjectMutation(
    updateDocumentObjectHandler,
    dealId,
    documentObject,
  );

  const getStatusColor = (status: string) => {
    if (status === 'Pending IR Review') return 'orange';
    if (status === 'Confirmed by IR') return 'green';
    if (status === 'QC Confirmed') return 'green';
    return 'red';
  };

  const lastModifiedDate = documentObject.properties.fileLastModifiedDate
    ? format(parseISO(documentObject.properties.fileLastModifiedDate), 'P')
    : '';

  const uploadFileHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileName = await uploadFile(
        token,
        dealId,
        file.name,
        'documents',
        file,
      );
      await updateDocumentProperties(token, [documentObject.id], {
        s3FileName: fileName,
      });
    }
  };

  const uploadFileMutation = getDocumentObjectMutationForUploadProperties(
    uploadFileHandler,
    dealId,
    documentObject,
  );

  return (
    <>
      <TableCell align="center" sx={statusColumnStyle.containerStyle}>
        <Box
          sx={{
            width: '12px',
            height: '12px',
            backgroundColor:
              documentObject.properties.status !== 'Confirmed by IR'
                ? getStatusColor(documentObject.properties.status)
                : null,
            border: `4px solid ${getStatusColor(
              documentObject.properties.status,
            )}`,
            borderRadius: '50%',
            margin: '0 auto',
          }}
        />
      </TableCell>
      <TableCell sx={documentNameColumnStyle.containerStyle}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TableBoldedTypography text={documentObject.properties.displayName} />
          <UploadButton mutationMethod={uploadFileMutation} />
        </Box>
        {!!documentObject.properties.s3FileName && (
          <DownloadButton
            dealId={dealId}
            fileInformation={{
              fileName: documentObject.properties.s3FileName,
              fileType: 'documents',
            }}
          />
        )}
      </TableCell>
      <TableCell sx={uploadDateColumnStyle.containerStyle}>
        <Tooltip title={lastModifiedDate} enterDelay={0}>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {lastModifiedDate}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell sx={rotateFileColumnStyle.containerStyle}>
        {!!documentObject.properties.s3FileName &&
          documentObject.properties.contentType &&
          allowedContentTypes(documentObject.properties.contentType) && (
            <RotateDropdownMenu
              dealId={dealId}
              fileName={documentObject.properties.s3FileName}
            />
          )}
      </TableCell>
      {/* <TableCell>
        <Autocomplete
          multiple
          limitTags={1}
          options={documentProperties ?? []}
          disableCloseOnSelect
          size="small"
          onChange={(event, value) => setDataAssociation(value)}
          getOptionLabel={(option) => option.properties.name}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox sx={{ marginRight: 8 }} checked={selected} />
              {option.properties.name}
            </li>
          )}
          renderTags={(tagValue, getTagProps) => (
            <Box>
              <Chip
                {...getTagProps({ index: 0 })}
                size="small"
                label={tagValue?.[0].properties.name}
              />
              {tagValue.length > 1 && `+${tagValue.length - 1}`}
            </Box>
          )}
          renderInput={(params) => (
            <TextField {...params} placeholder="Data Set(s) Association" />
          )}
        />
      </TableCell> */}
      <TableCell sx={dataStatusColumnStyle.containerStyle}>
        <FormControl sx={dataStatusColumnStyle.menuButtonStyle} size="small">
          <Select
            value={documentObject.properties.status}
            onChange={(event) =>
              updateDocumentObjectMutation.mutate({
                status: event.target.value,
                invalidReason:
                  event.target.value === 'Not Yet Received'
                    ? documentObject.properties.invalidReason
                    : '',
              })
            }
            displayEmpty
          >
            {statusOptions.map((statusOption) => (
              <MenuItem
                key={statusOption.hubspotName}
                value={statusOption.hubspotName}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: '8px',
                      minWidth: '8px',
                      height: '8px',
                      backgroundColor:
                        statusOption.hubspotName !== 'Confirmed by IR'
                          ? getStatusColor(statusOption.hubspotName)
                          : null,
                      border: `2px solid ${getStatusColor(
                        statusOption.hubspotName,
                      )}`,
                      borderRadius: '50%',
                      margin: '0 auto',
                    }}
                  />
                  <Typography
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      paddingLeft: 1,
                      flexGrow: 1,
                    }}
                  >
                    {statusOption.displayName}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell sx={invalidReasonsColumnStyle.containerStyle}>
        <FormControl
          sx={invalidReasonsColumnStyle.menuButtonStyle}
          size="small"
        >
          {!isLoadingInvalidReasonOptions &&
            documentObject.properties.status === 'Not Yet Received' && (
              <>
                <Select
                  value={documentObject.properties.invalidReason ?? ''}
                  onChange={(event) =>
                    updateDocumentObjectMutation.mutate({
                      invalidReason: event.target.value,
                    })
                  }
                >
                  {invalidReasons?.map((reason) =>
                    reason.value === '' ? (
                      <MenuItem key="none" value="">
                        None
                      </MenuItem>
                    ) : (
                      <MenuItem key={reason.value} value={reason.value}>
                        {reason.label}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </>
            )}
        </FormControl>
      </TableCell>
      <TableCell align="center" sx={noteColumnStyle.containerStyle}>
        <EditDocumentModal documentObject={documentObject} dealId={dealId} />
      </TableCell>
    </>
  );
};
