/**
 * The total of all widths in containerStyle must equal 80vw
 * This is because there is already padding on the left and right side of the page that equals 20vw
 */
export const sharedContainerStyle = {
  padding: 0,
  paddingY: 1,
  boxSizing: 'border-box',
};

export const statusColumnStyle = {
  containerStyle: {
    maxWidth: '4vw',
    minWidth: '4vw',
    ...sharedContainerStyle,
  },
};
export const documentNameColumnStyle = {
  containerStyle: {
    maxWidth: '24vw',
    minWidth: '24vw',
    ...sharedContainerStyle,
  },
};
export const uploadDateColumnStyle = {
  containerStyle: {
    maxWidth: '7vw',
    minWidth: '7vw',
    ...sharedContainerStyle,
  },
};
export const rotateFileColumnStyle = {
  containerStyle: {
    maxWidth: '8vw',
    minWidth: '8vw',
    ...sharedContainerStyle,
  },
  menuButtonStyle: {
    maxWidth: '7vw',
    minWidth: '7vw',
    boxSizing: 'border-box',
  },
};
export const dataStatusColumnStyle = {
  containerStyle: {
    maxWidth: '13vw',
    minWidth: '13vw',
    ...sharedContainerStyle,
  },
  menuButtonStyle: {
    maxWidth: '12vw',
    minWidth: '12vw',
    boxSizing: 'border-box',
  },
};
export const invalidReasonsColumnStyle = {
  containerStyle: {
    maxWidth: '21vw',
    minWidth: '21vw',
    ...sharedContainerStyle,
  },
  menuButtonStyle: {
    maxWidth: '20vw',
    minWidth: '20vw',
    boxSizing: 'border-box',
  },
};

export const noteColumnStyle = {
  containerStyle: {
    maxWidth: '3vw',
    minWidth: '3vw',
    ...sharedContainerStyle,
  },
};
