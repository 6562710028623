import { Typography, Box, Divider } from '@mui/material';
import { getBqfDisplayNames, getDealProperties } from 'api';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { LoadingIndicator } from 'components/LoadingIndiciator';
import { useUserToken } from 'dataLayer';
import {
  CACHE_TIME,
  STALE_TIME,
  errorHandler,
  generateBqfDisplayNamesQueryKey,
  generateDealObjectQueryKey,
} from 'helpers';
import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { RouteProps, useParams } from 'react-router-dom';
import { DealObject, DealObjectProperties } from 'types';

export const BusinessQualifyForm: FunctionComponent<RouteProps> = () => {
  const token = useUserToken();
  const { dealId } = useParams() as { dealId: string };
  const { data: dealProperties } = useQuery<DealObject | undefined>({
    queryKey: generateDealObjectQueryKey(token, dealId),
    queryFn: () => getDealProperties(token, dealId),
    cacheTime: CACHE_TIME,
    staleTime: STALE_TIME,
    onError: (error) => errorHandler(error),
  });

  const { data: bqfDisplayNames, isLoading: isLoadingBqfDisplayNames } =
    useQuery<DealObject | undefined>({
      queryKey: generateBqfDisplayNamesQueryKey(token),
      queryFn: () => getBqfDisplayNames(token),
      cacheTime: CACHE_TIME,
      staleTime: STALE_TIME,
      onError: (error) => errorHandler(error),
    });

  return (
    <>
      <Header />
      <Box
        sx={{
          display: 'flex',
          marginTop: 2,
        }}
      >
        <Typography
          fontFamily="Crique Grotesk"
          fontSize="25px"
          color="rgba(23,24,92,250)"
        >
          Refund Application
        </Typography>
        <Divider
          orientation="vertical"
          sx={{
            borderWidth: 1,
            borderColor: 'rgba(23,24,92,250)',
            marginX: 1,
          }}
          flexItem
        />
        <Typography
          fontFamily="Crique Grotesk"
          fontSize="25px"
          color="rgba(128, 128, 128)"
        >
          Company Information
        </Typography>
      </Box>
      <Typography
        fontFamily="Crique Grotesk"
        fontSize="18px"
        color="rgba(59 59 59);"
        marginTop={1}
      >
        {`${dealProperties?.properties.companyName ?? ''} (${dealId})`}
      </Typography>
      <Divider
        sx={{
          borderWidth: 1,
          borderColor: 'rgba(23,24,92,250)',
          width: '60%',
          marginBottom: 4,
        }}
      />
      {isLoadingBqfDisplayNames ? (
        <LoadingIndicator />
      ) : (
        <>
          {Object.entries(bqfDisplayNames ?? []).map(([key, value]) => (
            <>
              <Typography
                fontFamily="Crique Grotesk"
                fontSize="18px"
                fontWeight="500"
                lineHeight="18.4px"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {value}
              </Typography>
              <Typography
                fontFamily="Crique Grotesk"
                sx={{ paddingLeft: 2, paddingY: 1.5 }}
              >
                {dealProperties?.properties[key as keyof DealObjectProperties]}
              </Typography>
              <Divider
                sx={{
                  borderWidth: 1,
                  borderColor: 'darkgray',
                  marginBottom: 4,
                }}
              />
            </>
          ))}
        </>
      )}
      <Footer />
    </>
  );
};
