import { Auth, CognitoUser } from '@aws-amplify/auth';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useAsync } from '@react-hookz/web';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { COGNITO_QUERY_KEY, errorHandler } from 'helpers';
import { FunctionComponent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { RouteProps } from 'react-router';
import { MFACode } from './MFACode';
import { ResetPassword } from './ResetPassword';
import { UpdatePassword } from './UpdatePassword';

export const Login: FunctionComponent<RouteProps> = () => {
  const qc = useQueryClient();
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isFirstTimeNewPassword, setIsFirstTimeNewPassword] = useState(false);
  const [isSubmittingMFACode, setIsSubmittingMFACode] = useState(false); // User has MFA enabled and needs code to sign in
  const [authQRCode, setAuthQRCode] = useState<string>();
  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const generateSetupTOTPCode = async (user: CognitoUser | undefined) => {
    try {
      const userMFASecret = await Auth.setupTOTP(user);
      const code = `otpauth://totp/AWSCognito:${user?.getUsername()}?secret=${userMFASecret}&issuer=Cognito`;
      return code;
    } catch (error) {
      errorHandler(error);
      return undefined;
    }
  };

  const [formSubmitState, formSubmitAction] = useAsync(
    async (data: { email: string; password: string }) => {
      try {
        const signInResponse = await Auth.signIn(data.email, data.password);
        if (signInResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setCognitoUser(signInResponse);
          setIsFirstTimeNewPassword(true);
        } else if (signInResponse.challengeName === 'SOFTWARE_TOKEN_MFA') {
          setCognitoUser(signInResponse);
          setIsSubmittingMFACode(true);
        } else if (!signInResponse.challengeName) {
          setCognitoUser(signInResponse);
          const authCode = await generateSetupTOTPCode(signInResponse);
          setAuthQRCode(authCode);
        } else {
          qc.invalidateQueries(COGNITO_QUERY_KEY);
        }
      } catch (error) {
        errorHandler(error);
      }
    },
  );

  const completeNewPasswordHandler = async (newPassword: string) => {
    try {
      await Auth.completeNewPassword(cognitoUser, newPassword);
      qc.invalidateQueries(COGNITO_QUERY_KEY);
    } catch (error) {
      errorHandler(error);
    }
  };

  const verifyTotpTokenHandler = async (code: string) => {
    try {
      await Auth.verifyTotpToken(cognitoUser, code);
      await Auth.setPreferredMFA(cognitoUser, 'TOTP');
      qc.invalidateQueries(['cognito']);
    } catch (error) {
      errorHandler(error);
    }
  };

  const confirmSignInHandler = async (code: string) => {
    try {
      await Auth.confirmSignIn(cognitoUser, code, 'SOFTWARE_TOKEN_MFA');
      qc.invalidateQueries(['cognito']);
    } catch (error) {
      errorHandler(error);
    }
  };

  const renderLogin = () => {
    if (isSubmittingMFACode) {
      return <MFACode onMFACodeSubmit={(code) => confirmSignInHandler(code)} />;
    }
    if (authQRCode) {
      return (
        <MFACode
          authQRCode={authQRCode}
          goBack={() => {
            qc.invalidateQueries(COGNITO_QUERY_KEY);
          }}
          onMFACodeSubmit={(code) => verifyTotpTokenHandler(code)}
        />
      );
    }
    if (isResettingPassword) {
      return (
        <ResetPassword
          goBack={() => setIsResettingPassword(false)}
          email={getValues().email}
        />
      );
    }
    if (isFirstTimeNewPassword) {
      return (
        <UpdatePassword
          onSubmitMethod={(newPassword) =>
            completeNewPasswordHandler(newPassword)
          }
        />
      );
    }
    return (
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        onSubmit={handleSubmit(formSubmitAction.execute)}
      >
        <Typography sx={{ margin: 1 }} variant="h4">
          Sign In
        </Typography>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ margin: 1 }}
              label="Email"
              type="email"
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ margin: 1 }}
              label="Password"
              type="password"
            />
          )}
        />
        <Button sx={{ margin: 1 }} variant="outlined" type="submit">
          {formSubmitState.status === 'loading' && (
            <CircularProgress size={20} style={{ marginRight: 20 }} />
          )}
          Login
        </Button>
        <Button
          variant="text"
          size="small"
          onClick={() => setIsResettingPassword(true)}
        >
          Forgot Password
        </Button>
      </form>
    );
  };

  return (
    <>
      <Header />
      <Box sx={{ padding: 3, paddingX: '25%', height: '70vh' }}>
        {renderLogin()}
      </Box>
      <Footer />
    </>
  );
};
