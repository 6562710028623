export const generateDealIdsQueryKey = (
  token: string | undefined,
  prefix: string,
) => ['data', 'dealIds', token, prefix];

export const generateDealObjectQueryKey = (
  token: string | undefined,
  dealId: string,
) => ['data', 'dealProperties', token, dealId];

export const generateDocumentObjectsQueryKey = (
  token: string | undefined,
  dealId: string,
) => ['data', 'documentProperties', token, dealId];

export const generateBqfDisplayNamesQueryKey = (token: string | undefined) => [
  'data',
  'bqfDisplayNames',
  token,
];

export const generateInvalidReasonsQueryKey = (token: string | undefined) => [
  'data',
  'invalidReasons',
  token,
];

export const generate2848FilesQueryKey = (
  token: string | undefined,
  dealId: string,
) => ['data', '2848Form', token, dealId];

export const generateCreditObjectsQueryKey = (
  token: string | undefined,
  dealId: string,
) => ['data', 'credits', token, dealId];

export const COGNITO_QUERY_KEY = ['cognito'];
export const COGNITO_CURRENT_USER_QUERY_KEY = ['cognito', 'currentUser'];
export const COGNITO_IS_AUTHENTICATED_QUERY_KEY = [
  'cognito',
  'isAuthenticated',
];
