import React, { FunctionComponent, useRef } from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { UseMutationResult } from 'react-query/types/react/types';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutationMethod: UseMutationResult<void, unknown, any, unknown>;
}

export const UploadButton: FunctionComponent<IProps> = ({ mutationMethod }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        ref={inputRef}
        onChange={mutationMethod.mutate}
        onClick={(event) => {
          // eslint-disable-next-line no-param-reassign
          event.currentTarget.value = '';
        }}
        type="file"
        hidden
      />
      <Button
        disabled={mutationMethod.isLoading}
        disableRipple
        variant="text"
        onClick={() => inputRef.current?.click()}
        sx={{
          '&.MuiButton-text': {
            textTransform: 'none',
          },
          '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
          },
        }}
      >
        {mutationMethod.isLoading ? (
          <CircularProgress size={20} sx={{ paddingX: 1 }} />
        ) : (
          <FileUploadIcon sx={{ color: '#23A56E' }} />
        )}
        <Typography
          fontFamily="Crique Grotesk"
          fontSize="16px"
          color="#23A56E"
          fontWeight="400"
          lineHeight="21px"
        >
          Upload
        </Typography>
      </Button>
    </>
  );
};
