/**
 * The total of all widths in containerStyle must equal 78vw
 * This is because there is already padding on the left and right side of the page that equals 20vw
 */
export const sharedContainerStyle = {
  paddingY: 1,
  boxSizing: 'border-box',
};

export const checkboxColumnStyle = {
  containerStyle: {
    textAlign: 'center',
    maxWidth: '8vw',
    minWidth: '8vw',
    ...sharedContainerStyle,
  },
};

export const formNameColumnStyle = {
  containerStyle: {
    maxWidth: '25vw',
    minWidth: '25vw',
    ...sharedContainerStyle,
  },
};

export const creditObjectColumnStyle = {
  containerStyle: {
    maxWidth: '45vw',
    minWidth: '45vw',
    ...sharedContainerStyle,
  },
};
