import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { RouteProps } from 'react-router';

export const Footer: FunctionComponent<RouteProps> = () => (
  <Typography
    sx={{
      color: '#8A8A8A',
      left: '10vw',
      bottom: 0,
      margin: 1,
    }}
    fontSize="14px"
    fontFamily="Crique Grotesk"
  >
    © 2022 Innovation Refunds. All rights reserved.
  </Typography>
);
