import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
  Box,
  Button,
  Checkbox,
  Modal,
  TableCell,
  Typography,
} from '@mui/material';
import { useDebouncedCallback } from '@react-hookz/web';
import {
  createCreditObject,
  getCreditObjects,
  updateCreditObjects,
  uploadFile,
} from 'api';
import { deleteCreditObject } from 'api/lambda';
import { DownloadButton, UploadButton } from 'components/Buttons';
import { LoadingIndicator } from 'components/LoadingIndiciator';
import { TableBoldedTypography } from 'components/Typography';
import { getCreateCreditObjectMutation, useUserToken } from 'dataLayer';
import {
  getCreditObjectMutation,
  getCreditObjectMutationForUploadProperties,
  getDeleteCreditObjectMutation,
} from 'dataLayer/creditObjectMutations';
import { CACHE_TIME, generateCreditObjectsQueryKey, STALE_TIME } from 'helpers';
import {
  checkboxColumnStyle,
  creditObjectColumnStyle,
  formNameColumnStyle,
} from 'pages/Deals/DealCPA';
import {
  ChangeEvent,
  FunctionComponent,
  SyntheticEvent,
  useState,
} from 'react';
import NumberFormat from 'react-number-format';
import { useQuery } from 'react-query';
import { CreditObject, CreditObjectProperties } from 'types';

interface IProps {
  dealId: string;
  formName: { displayName: string; hubspotName: string };
}

export const CpaCreditTableCells: FunctionComponent<IProps> = ({
  dealId,
  formName,
}) => {
  const token = useUserToken();

  const { data: creditObjects } = useQuery<CreditObject[]>({
    queryKey: generateCreditObjectsQueryKey(token, dealId),
    queryFn: () => getCreditObjects(token, dealId),
    cacheTime: CACHE_TIME,
    staleTime: STALE_TIME,
    onError: () => console.info('Error getting credit objects.'),
  });
  const creditObjectForThisForm = creditObjects?.find(
    (creditObject) => creditObject.properties.name === formName.hubspotName,
  );

  const createCreditObjectHandler = async (
    creditProperties: Partial<CreditObjectProperties>,
  ) => {
    await createCreditObject(token, dealId, creditProperties);
  };

  const createCreditObjectMutation = getCreateCreditObjectMutation(
    createCreditObjectHandler,
    dealId,
  );

  const deleteCreditObjectHandler = async (creditId: string) => {
    if (creditObjectForThisForm)
      await deleteCreditObject(token, dealId, creditId);
  };

  const deleteCreditObjectMutation = getDeleteCreditObjectMutation(
    deleteCreditObjectHandler,
    dealId,
  );

  const updateCreditObjectHandler = async (
    creditProperties: Partial<CreditObjectProperties>,
  ) => {
    if (creditObjectForThisForm) {
      await updateCreditObjects(
        token,
        [creditObjectForThisForm.id],
        creditProperties,
      );
    }
  };
  const uploadFileHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileName = await uploadFile(
        token,
        dealId,
        file.name,
        'taxDocuments',
        file,
      );
      if (creditObjectForThisForm)
        await updateCreditObjects(token, [creditObjectForThisForm.id], {
          s3FileName: fileName,
        });
    }
  };

  const uploadFileMutation = getCreditObjectMutationForUploadProperties(
    uploadFileHandler,
    dealId,
    creditObjectForThisForm,
  );

  const updateCreditObjectMutation = getCreditObjectMutation(
    updateCreditObjectHandler,
    dealId,
  );

  const updateEstimatedRefundAmount = useDebouncedCallback(
    (input) =>
      updateCreditObjectMutation.mutate({
        name: creditObjectForThisForm?.properties.name,
        estimatedRefundAmount: input,
      }),
    [],
    1000,
    0,
  );

  const [openModal, setOpenModal] = useState(false);
  const handleClose = (event: SyntheticEvent, reason: string) => {
    if (reason && reason === 'backdropClick') return;
    setOpenModal(false);
  };

  return (
    <>
      <TableCell sx={checkboxColumnStyle.containerStyle}>
        {createCreditObjectMutation.isLoading ||
        deleteCreditObjectMutation.isLoading ? (
          <LoadingIndicator />
        ) : (
          <Checkbox
            color="default"
            checked={!!creditObjectForThisForm ?? false}
            onClick={() => {
              if (creditObjectForThisForm) {
                setOpenModal(true);
              } else {
                createCreditObjectMutation.mutate({
                  name: formName.hubspotName,
                });
              }
            }}
          />
        )}
      </TableCell>
      <TableCell
        sx={{
          ...formNameColumnStyle.containerStyle,
          backgroundColor: creditObjectForThisForm ? null : 'lightgray',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TableBoldedTypography text={formName.displayName} />
          {!!creditObjectForThisForm && (
            <UploadButton mutationMethod={uploadFileMutation} />
          )}
        </Box>
        {!!creditObjectForThisForm?.properties.s3FileName && (
          <DownloadButton
            dealId={dealId}
            fileInformation={{
              fileName: creditObjectForThisForm?.properties.s3FileName,
              fileType: 'taxDocuments',
            }}
          />
        )}
      </TableCell>
      <TableCell
        sx={{
          ...creditObjectColumnStyle.containerStyle,
          backgroundColor: creditObjectForThisForm ? null : 'lightgray',
        }}
      >
        {!!creditObjectForThisForm?.properties.s3FileName && (
          <Box sx={{ display: 'flex' }}>
            <AttachMoneyIcon
              sx={{
                borderRadius: '4px 0 0 4px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                height: 'inherit',
                padding: 1,
              }}
            />
            <NumberFormat
              thousandSeparator
              decimalSeparator="."
              decimalScale={2}
              fixedDecimalScale
              style={{
                borderRadius: '0 4px 4px 0',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'rgba(0, 0, 0, 0.23)',
                paddingLeft: '8px',
              }}
              type="text"
              defaultValue={
                creditObjectForThisForm?.properties.estimatedRefundAmount ?? 0
              }
              onValueChange={(values: { value: string }) =>
                updateEstimatedRefundAmount(values.value)
              }
            />
          </Box>
        )}
      </TableCell>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="delete-credit-object-modal-title"
        aria-describedby="delete-credit-object-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 1,
            py: 3,
            px: 2,
          }}
        >
          <Typography id="delete-credit-object-modal-title">
            Are you sure you want to delete this credit object?
          </Typography>
          <Box sx={{ marginTop: 1, display: 'flex' }}>
            <Button
              sx={{ marginRight: 0.5, flexGrow: 1, flexBasis: 0 }}
              variant="outlined"
              color="error"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{ marginRight: 0.5, flexGrow: 1, flexBasis: 0 }}
              variant="outlined"
              color="success"
              onClick={() => {
                if (creditObjectForThisForm)
                  deleteCreditObjectMutation.mutate(creditObjectForThisForm.id);
                setOpenModal(false);
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
