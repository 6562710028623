import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import { useAsync } from '@react-hookz/web';
import { getDealProperties, getZippedFiles } from 'api';
import { useUserToken } from 'dataLayer';
import {
  CACHE_TIME,
  errorHandler,
  generateDealObjectQueryKey,
  STALE_TIME,
} from 'helpers';
import { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { PATHS } from 'Router';
import { DealObject } from 'types';

interface IProps {
  dealId: string;
}

export const DealHeader: FunctionComponent<IProps> = ({ dealId }) => {
  const token = useUserToken();
  const { data: dealProperties } = useQuery<DealObject | undefined>({
    queryKey: generateDealObjectQueryKey(token, dealId),
    queryFn: () => getDealProperties(token, dealId),
    cacheTime: CACHE_TIME,
    staleTime: STALE_TIME,
    onError: (error) => errorHandler(error),
  });
  const [downloadZipState, downloadZipAction] = useAsync(async () => {
    try {
      const file = await getZippedFiles(token, dealId, 'documents');
      if (file) window.open(file.url, '_blank');
    } catch (error) {
      errorHandler(error);
    }
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          marginTop: 2,
          '& hr': {
            mx: 1,
          },
        }}
      >
        <Typography
          fontFamily="Crique Grotesk"
          fontSize="25px"
          color="rgba(23,24,92,250)"
        >
          Refund Application
        </Typography>
        <Divider
          orientation="vertical"
          sx={{
            borderWidth: 1,
            borderColor: 'rgba(23,24,92,250)',
          }}
          flexItem
        />
        <Typography
          fontFamily="Crique Grotesk"
          fontSize="25px"
          color="rgba(128, 128, 128)"
        >
          Document Upload
        </Typography>
        <Button
          variant="outlined"
          component={RouterLink}
          to={generatePath(PATHS.BQF, { dealId })}
          sx={{ marginLeft: 2 }}
        >
          View Company Information
        </Button>
        <Button
          disabled={downloadZipState.status === 'loading'}
          variant="contained"
          sx={{ marginLeft: 2 }}
          onClick={downloadZipAction.execute}
        >
          {downloadZipState.status === 'loading' ? (
            <CircularProgress size={20} />
          ) : (
            'Download Files'
          )}
        </Button>
      </Box>
      <Typography
        fontFamily="Crique Grotesk"
        fontSize="18px"
        color="rgba(59 59 59);"
        marginTop={1}
      >
        {`${dealProperties?.properties.companyName ?? ''} (${dealId})`}
      </Typography>
      <Divider
        sx={{
          borderWidth: 1,
          borderColor: 'rgba(23,24,92,250)',
          width: '60%',
          marginBottom: 4,
        }}
      />
    </>
  );
};
