import { Box, GlobalStyles } from '@mui/material';
import { Amplify } from 'aws-amplify';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactQueryDevtools } from 'react-query/devtools';
import Router from './Router';

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID, // UserPool ID
    region: 'us-east-1',
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID, // WebClientId
  },
});

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles
        styles={{
          body: { margin: 0 },
          '@font-face': {
            fontFamily: 'Crique Grotesk',
            src:
              'url("//db.onlinewebfonts.com/t/7ac5b5e00dfb4c46d71852429dc4eafc.woff2") format("woff2"),' +
              'url("//db.onlinewebfonts.com/t/7ac5b5e00dfb4c46d71852429dc4eafc.woff") format("woff")',
          },
        }}
      />
      <Box
        sx={{
          paddingLeft: '10vw',
          paddingRight: '10vw',
        }}
      >
        <Router />
      </Box>
      <ToastContainer />
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
