import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';

interface IProps {
  text: string;
}

export const TableBoldedTypography: FunctionComponent<IProps> = ({ text }) => (
  <Typography
    fontFamily="Crique Grotesk"
    fontSize="16px"
    color="#3D3D3D"
    fontWeight="700"
    lineHeight="21px"
  >
    {text}
  </Typography>
);
