import { Auth } from '@aws-amplify/auth';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAsync } from '@react-hookz/web';
import { errorHandler, successHandler } from 'helpers';
import { UpdatePassword } from '.';

interface IProps {
  email: string;
  goBack: () => void;
}

export const ResetPassword: FunctionComponent<IProps> = ({ email, goBack }) => {
  const [showCodeField, setShowCodeField] = useState(false);
  const [isSettingNewPassword, setIsSettingNewPassword] = useState(false);
  const formSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email(),
    code: Yup.string().test(
      'test-number-regex-only-if-not-empty',
      'Reset code must be 6 digits',
      (code) =>
        !code || (/^[0-9]+$/.test(code) && code.toString().length === 6),
    ),
  });

  const formOptions = {
    resolver: yupResolver(formSchema),
    defaultValues: {
      email,
      code: '',
    },
  };
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    setError,
  } = useForm(formOptions);

  const [sendResetCodeState, sendResetCodeAction] = useAsync(
    async (data: Record<string, string>) => {
      try {
        await Auth.forgotPassword(data.email);
        successHandler('Code sent successfully. Please check your email.');
        setShowCodeField(true);
      } catch (error) {
        errorHandler(error);
      }
    },
  );

  const onSubmit = async (data: Record<string, string>) => {
    if (data.code.toString().length === 0) {
      if (showCodeField) {
        setError('code', { message: 'Reset code is required' });
      } else {
        sendResetCodeAction.execute(data);
      }
    } else {
      setIsSettingNewPassword(true);
    }
  };

  const updatePasswordHandler = async (newPassword: string) => {
    try {
      const data = getValues();
      await Auth.forgotPasswordSubmit(data.email, data.code, newPassword);
      successHandler(
        'Password successfully reset! Please log in using your new credentials.',
      );
      goBack();
    } catch (error) {
      setIsSettingNewPassword(false);
      errorHandler(error);
    }
  };

  const renderResetPassword = () => {
    if (isSettingNewPassword) {
      return (
        <UpdatePassword
          onSubmitMethod={(newPassword) => updatePasswordHandler(newPassword)}
        />
      );
    }
    return (
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography sx={{ margin: 1 }} variant="h4">
          Forgot Password
        </Typography>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.email}
              helperText={errors.email?.message}
              sx={{ margin: 1 }}
              label="Email"
              type="email"
            />
          )}
        />
        {!showCodeField ? (
          <Button sx={{ margin: 1 }} variant="outlined" type="submit">
            {sendResetCodeState.status === 'loading' && (
              <CircularProgress size={20} style={{ marginRight: 20 }} />
            )}
            Send Code
          </Button>
        ) : (
          <>
            <Controller
              name="code"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.code}
                  helperText={errors.code?.message}
                  sx={{ margin: 1 }}
                  label="Code"
                  inputProps={{
                    maxLength: 6,
                  }}
                />
              )}
            />
            <Box sx={{ margin: 1, display: 'flex' }}>
              <Button
                sx={{ marginRight: 0.5, flexGrow: 1, flexBasis: 0 }}
                variant="outlined"
                onClick={() => sendResetCodeAction.execute(getValues())}
              >
                {sendResetCodeState.status === 'loading' && (
                  <CircularProgress size={20} style={{ marginRight: 20 }} />
                )}
                Request another code
              </Button>
              <Button
                sx={{ marginLeft: 0.5, flexGrow: 1, flexBasis: 0 }}
                variant="outlined"
                type="submit"
              >
                Continue
              </Button>
            </Box>
          </>
        )}
        <Button
          sx={{ margin: 1 }}
          variant="outlined"
          color="error"
          onClick={goBack}
        >
          Cancel
        </Button>
      </form>
    );
  };

  return <>{renderResetPassword()}</>;
};
