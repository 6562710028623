import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from '@mui/material';
import { Footer } from 'components/Footer';
import { DealHeader, Header } from 'components/Header';
import { DownloadButton, UploadButton } from 'components/Buttons';
import {
  archiveFile,
  getCreditObjects,
  getFilesForDeal,
  uploadFile,
} from 'api';
import { CpaCreditTableCells } from 'components/TableCells';
import { getMutationFor2848Files, useUserToken } from 'dataLayer';
import {
  CACHE_TIME,
  generate2848FilesQueryKey,
  generateCreditObjectsQueryKey,
  STALE_TIME,
} from 'helpers';
import React, { ChangeEvent, FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { RouteProps, useParams } from 'react-router-dom';
import { CreditObject, S3File } from 'types';
import { LoadingIndicator } from 'components/LoadingIndiciator';
import {
  TableHeaderTypography,
  TableCommonTypography,
  TableBoldedTypography,
} from 'components/Typography';
import {
  checkboxColumnStyle,
  creditObjectColumnStyle,
  formNameColumnStyle,
} from './formTableStyles';

export const DealCPA: FunctionComponent<RouteProps> = () => {
  const { dealId } = useParams() as { dealId: string };
  const token = useUserToken();

  const { data: filesFor2848Form, isLoading: isLoadingFilesFor2848Form } =
    useQuery<S3File[]>({
      queryKey: generate2848FilesQueryKey(token, dealId),
      queryFn: () => getFilesForDeal(token, dealId, '2848'),
      cacheTime: CACHE_TIME,
      staleTime: STALE_TIME,
      onError: () => console.info('2848 does not exist.'),
    });

  const { isLoading: isLoadingCreditObjects } = useQuery<CreditObject[]>({
    queryKey: generateCreditObjectsQueryKey(token, dealId),
    queryFn: () => getCreditObjects(token, dealId),
    cacheTime: CACHE_TIME,
    staleTime: STALE_TIME,
    onError: () => console.info('Error getting credit objects.'),
  });

  const upload2848FileHandler = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (filesFor2848Form) {
      await Promise.all(
        filesFor2848Form.map(async (formFile) => {
          await archiveFile(token, dealId, formFile.name, '2848');
        }),
      );
    }
    const file = event.target.files?.[0];
    if (file) {
      await uploadFile(token, dealId, file.name, '2848', file);
    }
  };

  const upload2848FormMutation = getMutationFor2848Files(
    upload2848FileHandler,
    dealId,
  );

  const forms = [
    { displayName: '941x - 2020 Q2', hubspotName: '2020 ERC | Q2' },
    { displayName: '941x - 2020 Q3', hubspotName: '2020 ERC | Q3' },
    { displayName: '941x - 2020 Q4', hubspotName: '2020 ERC | Q4' },
    { displayName: '941x - 2021 Q1', hubspotName: '2021 ERC | Q1' },
    { displayName: '941x - 2021 Q2', hubspotName: '2021 ERC | Q2' },
    { displayName: '941x - 2021 Q3', hubspotName: '2021 ERC | Q3' },
    { displayName: '941x - 2021 Q4', hubspotName: '2021 ERC | Q4' },
    { displayName: '943x - 2020', hubspotName: '2020 ERC | 943' },
    { displayName: '943x - 2021', hubspotName: '2021 ERC | 943' },
  ];

  return (
    <>
      <Header />
      <DealHeader dealId={dealId} />
      <Typography
        fontFamily="Crique Grotesk"
        fontSize="20px"
        color="#3D3D3D"
        fontWeight="700"
        lineHeight="23px"
        marginY={2}
      >
        2848 Form
      </Typography>
      {isLoadingFilesFor2848Form ? (
        <LoadingIndicator />
      ) : (
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                height: '75px',
              }}
            >
              <TableRow
                sx={{
                  backgroundColor: '#DDE4EF',
                }}
              >
                <TableCell>
                  <TableHeaderTypography text="Form Name" />
                  <TableCommonTypography text="Upload Document Name" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TableBoldedTypography text="2848" />
                    <UploadButton mutationMethod={upload2848FormMutation} />
                  </Box>
                  {!!filesFor2848Form?.[0]?.name && (
                    <DownloadButton
                      dealId={dealId}
                      fileInformation={{
                        fileName: filesFor2848Form[0].name,
                        fileType: '2848',
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Typography
        fontFamily="Crique Grotesk"
        fontSize="20px"
        color="#3D3D3D"
        fontWeight="700"
        lineHeight="23px"
        marginY={2}
        paddingTop={2}
      >
        941x / 943x Forms
      </Typography>
      {isLoadingCreditObjects ? (
        <LoadingIndicator />
      ) : (
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                height: '75px',
              }}
            >
              <TableRow
                sx={{
                  backgroundColor: '#DDE4EF',
                }}
              >
                <TableCell sx={checkboxColumnStyle.containerStyle}>
                  <TableHeaderTypography text="Qualifies for credit" />
                </TableCell>
                <TableCell sx={formNameColumnStyle.containerStyle}>
                  <TableHeaderTypography text="Form Name" />
                  <TableCommonTypography text="Upload Document Name" />
                </TableCell>
                <TableCell sx={creditObjectColumnStyle.containerStyle}>
                  <TableHeaderTypography text="Confirm Qualifying Credits" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {forms.map((form) => (
                <TableRow
                  key={form.hubspotName}
                  sx={{
                    boxShadow: '0px 1px 0px lightgray',
                  }}
                >
                  <CpaCreditTableCells dealId={dealId} formName={form} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Footer />
    </>
  );
};
