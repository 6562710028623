import { Button, Tooltip } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useAsync } from '@react-hookz/web';
import { getFile } from 'api';
import { useUserToken } from 'dataLayer';
import { errorHandler } from 'helpers';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { TableCommonTypography } from 'components/Typography';
import { LoadingIndicator } from 'components/LoadingIndiciator';

interface IProps {
  dealId: string;
  fileInformation: { fileName: string; fileType: string };
}

export const DownloadButton: FunctionComponent<IProps> = ({
  dealId,
  fileInformation,
}) => {
  const token = useUserToken();

  const [downloadState, downloadAction] = useAsync(async () => {
    try {
      const file = await getFile(
        token,
        dealId,
        fileInformation.fileName,
        fileInformation.fileType,
      );
      if (file) window.open(file.url, '_blank');
    } catch (error) {
      errorHandler(error);
    }
  });

  return (
    <Tooltip title={fileInformation.fileName} enterDelay={0}>
      <Button
        disabled={downloadState.status === 'loading'}
        disableRipple
        variant="text"
        onClick={downloadAction.execute}
        sx={{
          padding: 0,
          '&.MuiButton-text': {
            textTransform: 'none',
          },
          '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
          },
          maxWidth: 'inherit',
        }}
      >
        <TableCommonTypography
          text={fileInformation.fileName}
          styles={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        />
        {downloadState.status === 'loading' ? (
          <LoadingIndicator size={20} />
        ) : (
          <FileDownloadIcon sx={{ color: '#5A5A5A' }} />
        )}
      </Button>
    </Tooltip>
  );
};
