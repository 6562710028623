import {
  Button,
  CircularProgress,
  Icon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useAsync } from '@react-hookz/web';
import { rotateFile } from 'api';
import { useUserToken } from 'dataLayer';
import { errorHandler, successHandler } from 'helpers';
import { rotateFileColumnStyle } from 'pages/Deals/Deal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  RotateLeftIcon,
  RotateRightIcon,
  Rotate180Icon,
} from '../../assets/icons';

interface IProps {
  dealId: string;
  fileName: string;
}

export const RotateDropdownMenu: FunctionComponent<IProps> = ({
  dealId,
  fileName,
}) => {
  const token = useUserToken();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const showMenu = Boolean(anchorEl);

  const [rotateFileState, rotateFileAction] = useAsync(
    async (rotation: string) => {
      try {
        setAnchorEl(null);
        await rotateFile(token, dealId, fileName, rotation, 'documents');
        successHandler('The file has been rotated successfully.');
      } catch (error) {
        errorHandler(error);
      }
    },
  );

  return (
    <>
      <Button
        sx={{
          ...rotateFileColumnStyle.menuButtonStyle,
          color: '#8A8A8A',
          borderColor: '#8A8A8A',
          '&.MuiButton-outlined': {
            textTransform: 'none',
          },
        }}
        disabled={rotateFileState.status === 'loading'}
        variant="outlined"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {rotateFileState.status === 'loading' ? (
          <CircularProgress size={20} sx={{ paddingX: 1 }} />
        ) : (
          'Options'
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={showMenu}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          value="270"
          onClick={() => rotateFileAction.execute('270')}
          disableRipple
        >
          <Icon>
            <img src={RotateLeftIcon} />
          </Icon>
          <Typography paddingLeft={1}>Left</Typography>
        </MenuItem>
        <MenuItem
          value="90"
          onClick={() => rotateFileAction.execute('90')}
          disableRipple
        >
          <Icon>
            <img src={RotateRightIcon} />
          </Icon>
          <Typography paddingLeft={1}>Right</Typography>
        </MenuItem>
        <MenuItem
          value="180"
          onClick={() => rotateFileAction.execute('180')}
          disableRipple
        >
          <Icon>
            <img src={Rotate180Icon} />
          </Icon>
          <Typography paddingLeft={1}>180 degrees</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
