import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';

interface IProps {
  text: string;
  styles?: Record<string, unknown>;
}

export const TableCommonTypography: FunctionComponent<IProps> = ({
  text,
  styles,
}) => (
  <Typography
    fontFamily="Crique Grotesk"
    fontSize="14px"
    color="#5A5A5A"
    fontWeight="400"
    lineHeight="16.1px"
    sx={styles}
  >
    {text}
  </Typography>
);
