/* eslint-disable react/jsx-no-bind */
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useDebouncedCallback } from '@react-hookz/web';
import { getDealIds } from 'api';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { LoadingIndicator } from 'components/LoadingIndiciator';
import { useUserGroups, useUserToken } from 'dataLayer';
import { CACHE_TIME, generateDealIdsQueryKey, STALE_TIME } from 'helpers';
import { errorHandler } from 'helpers/utils';
import { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-query';
import { generatePath, Link as RouterLink, RouteProps } from 'react-router-dom';
import { PATHS } from 'Router';

export const DealsHome: FunctionComponent<RouteProps> = () => {
  const token = useUserToken();
  const userGroups = useUserGroups();

  const [prefix, setPrefix] = useState('');
  const { data: dealIds, isLoading } = useQuery<string[]>({
    queryKey: generateDealIdsQueryKey(token, prefix),
    queryFn: () => getDealIds(token, prefix),
    cacheTime: CACHE_TIME,
    staleTime: STALE_TIME,
    onError: (error) => errorHandler(error),
  });

  const updatePrefix = useDebouncedCallback(
    (input) => setPrefix(input ?? ''),
    [],
    300,
    0,
  );

  return (
    <>
      <Header />
      <TextField
        margin="normal"
        fullWidth
        id="outlined-search"
        label="Search by deal id"
        type="search"
        onChange={(event) => updatePrefix(event.target.value)}
      />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: '#dfe8f0',
                }}
              >
                <TableCell>Deal ID</TableCell>
                <TableCell>View Deal</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dealIds?.map((id) => (
                <TableRow
                  key={id}
                  sx={{
                    boxShadow: '0px 1px 0px lightgray',
                  }}
                >
                  <TableCell>{id}</TableCell>
                  <TableCell>
                    {userGroups.includes('csc') && (
                      <Button
                        variant="outlined"
                        component={RouterLink}
                        to={generatePath(PATHS.DOCUMENTS, { dealId: id })}
                        sx={{ marginRight: 1 }}
                      >
                        View Documents
                      </Button>
                    )}
                    {userGroups.includes('cpa') && (
                      <Button
                        variant="outlined"
                        component={RouterLink}
                        to={generatePath(PATHS.DEAL_CPA, { dealId: id })}
                      >
                        View Deal
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Footer />
    </>
  );
};
